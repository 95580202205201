/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
// import "./InplayControlGame.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import useUpdateCassinoGame from "../../hooks/Games/Casino/useUpdateCassinoGame";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function CasinoControlGame() {
  const navigate = useNavigate();
  const location = useLocation();

  const uuid = localStorage.getItem("UUID");
  const accountType = localStorage.getItem("account");
  const userPassword = localStorage.getItem("password");

  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: updateCassinoGame, isLoading: isUpdating } =
    useUpdateCassinoGame();

  let gameData = [];
  let gameId = "";
  if (location.state) {
    gameData = location.state;
    gameId = location.state?.id;
  }

  const [minLimit, setMinLimit] = useState(100);
  const [maxLimit, setMaxLimit] = useState(50000);

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      id: gameId,
      isLocked: gameData.isLocked,
      minLimit: minLimit,
      maxLimit: maxLimit,
    };

    updateCassinoGame(payload, {
      onSuccess: (response) => {
        toast.success("Game updated successfully");
        navigate("/casino-in-play");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  }

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(gameData);
    console.log(gameId);
    if (accountType === "admin" || accountType === "power_user") {
      console.log("ok");
    } else {
      navigate("/inplay");
      toast.error("You don't have access!");
    }

    if (gameData) {
      setMinLimit(gameData.minLimit);
      setMaxLimit(gameData.maxLimit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Casino Control Game</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/casino-in-play">Casino Inplay</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Casino Control Game</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row mt-3 mb-4">
          {/* General Form */}
          <div className="col-md-6 w-100 mb-3">
            <div className="accordion" id="accordionExample2">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    id="create-button-1"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    General
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">
                            Minimum Bet Amount
                          </label>
                          <input
                            type="number"
                            id="matchMin"
                            name="matchMin"
                            className="form-control"
                            value={minLimit}
                            required
                            onChange={(e) => {
                              setMinLimit(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">
                            Maximum Bet Amount
                          </label>
                          <input
                            type="number"
                            id="matchMax"
                            name="matchMax"
                            className="form-control"
                            value={maxLimit}
                            required
                            onChange={(e) => {
                              setMaxLimit(e.target.value);
                            }}
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary my-3"
                          disabled={isUpdating}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Action Buttons */}
        </div>
      </section>
    </div>
  );
}
