/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./cashtransaction.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import PageHeader from "../../Components/PageHeader";
import Card from "../../Components/Card";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useGetClientTotalCommission from "../../hooks/useGetClientTotalCommission";
import useResetClientTotalCommission from "../../hooks/useResetClientTotalCommission";
import { dateFormatter } from "../../utils/dateFormatter";
import Select from "react-select";
import Swal from "sweetalert2";

export default function ClientTotalComission(props) {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");
  const navigate = useNavigate();

  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: getClientTotalCommission } = useGetClientTotalCommission();
  const { mutate: resetClientTotalCommission } =
    useResetClientTotalCommission();

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [user, setUser] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  function changeUser(value) {
    if (value === "all" || value === "") {
      setData(allData);
    } else {
      const filteredData = allData.filter(
        (item) => item.client_user_id === value
      );
      setData(filteredData);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (user === "" || fromDate === "" || toDate === "") {
      setData(allData);
    } else {
      const filteredData = allData.filter((item) => {
        return (
          item.client_user_id === user &&
          new Date(item.updatedAt) >= new Date(fromDate) &&
          new Date(item.updatedAt) <= new Date(toDate)
        );
      });

      setData(filteredData);
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      setIsFirstPage(currentPage - 1 <= 3);

      setIsLastPage(false);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      paginate(currentPage + 1);
      setIsFirstPage(false);
      setIsLastPage(
        currentPage + 1 >= Math.ceil(data.length / itemsPerPage) - 2
      );
    }
  };

  function handleResetCommission(id) {
    const payload = {
      comm_id: id,
    };

    Swal.fire({
      title: "Are you sure?",
      text: `You want to reset the commission. This action cannot be undone.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!",
    }).then((result) => {
      if (result.isConfirmed) {
        resetClientTotalCommission(payload, {
          onSuccess: (response) => {
            toast.success(response.message);
            window.location.reload();
          },
          onError: (error) => {
            toast.error(error.message);
          },
        });
      }
    });
  }

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dataPayload = {
      logged_acc_type: accountType,
      logged_user_id: uuid,
    };

    getClientTotalCommission(dataPayload, {
      onSuccess: (response) => {
        console.log(response);
        let userData = response.data.data;

        let users = [];

        userData.forEach((el) => {
          if (!users.some((ele) => ele.id === el.user_id)) {
            users.push({
              id: el.user_id,
              name: `${el.user_account.name}`,
              code: `${el.user_account.code}`,
            });
          }
        });

        console.log(users);

        setData(userData);
        setAllData(userData);
        setUsersList(users);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <PageHeader
        title="Commission Len Den
"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <Card header="Ledger">
              <div className="row">
                <div className="form-group col-md-3 mb-3">
                  <label className="form-label">Users</label>
                  <Select
                    options={[
                      { value: "", label: "Select" },
                      { value: "all", label: "All" },
                      ...usersList.map((user) => ({
                        value: user.id,
                        label: `${user.code} - ${user.name}`,
                      })),
                    ]}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={""}
                    isDisabled={false}
                    isLoading={usersList.length === 0 ? true : false}
                    isSearchable={true}
                    isRtl={false}
                    name="selectedUser"
                    onChange={(e) => {
                      const selected = e?.value;
                      setUser(selected);
                      changeUser(selected);
                    }}
                  />
                </div>
                <div className="form-group col-md-3 mb-3">
                  <label className="form-label">From Date</label>
                  <input
                    type="date"
                    name="from"
                    className="form-control"
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group col-md-3 mb-3">
                  <label className="form-label">To Date</label>
                  <input
                    type="date"
                    name="to"
                    className="form-control"
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group col-md-3 mb-3 align-self-end">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="parent-container">
                  <div className="col-6">
                    <div
                      className="label-1 dataTables_length"
                      id="example1_length"
                    >
                      <label>
                        Show
                        <select
                          className="custom-select"
                          onChange={(e) => {
                            setItemsPerPage(e.target.value);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive pl-4 pr-4">
                <table
                  id="datatable"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ fontWeight: "700" }}>
                        DATE <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        CODE <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        M Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        S Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        Total Comm <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        M Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        S Comm. <span id="sorted-icon">↑↓</span>
                      </th>
                      <th style={{ fontWeight: "700" }}>
                        Total Comm <span id="sorted-icon">↑↓</span>
                      </th>
                      <th>Comm Bacha</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <th>#</th>

                      <th></th>

                      <th>CLIENT</th>

                      <th
                        colSpan={3}
                        style={{ color: "green", fontSize: "16px" }}
                      >
                        UPER SE MILA
                      </th>

                      <th
                        colSpan={3}
                        style={{ color: "red", fontSize: "16px" }}
                      >
                        CLIENT KO DIYA
                      </th>
                      <th style={{ color: "blue", fontSize: "16px" }}>
                        TOTAL BACHA{" "}
                        <span id="sorted-icon" style={{ color: "blue" }}>
                          ↑↓
                        </span>
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length !== 0 ? (
                      currentItems?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{dateFormatter(item.updatedAt)}</td>
                          <td>
                            {item.user_account.code} {item.user_account.name}
                          </td>
                          <td
                            className="fw-semibold"
                            style={{ color: "green" }}
                          >
                            {item.m_amt < 0
                              ? 0
                              : (item.m_amt * (2 / 100)).toFixed(2)}
                          </td>
                          <td
                            className="fw-semibold"
                            style={{ color: "green" }}
                          >
                            {(item.s_amt_tot * (3 / 100)).toFixed(2)}
                          </td>
                          <td
                            className="fw-semibold"
                            style={{ color: "green" }}
                          >
                            {item.m_amt < 0
                              ? item.s_amt_tot * (3 / 100)
                              : (
                                  item.m_amt * (2 / 100) +
                                  item.s_amt_tot * (3 / 100)
                                ).toFixed(2)}
                          </td>

                          <td className="fw-semibold" style={{ color: "red" }}>
                            {item.m_amt < 0
                              ? 0
                              : (item.m_amt * (2 / 100)).toFixed(2)}
                          </td>
                          <td className="fw-semibold" style={{ color: "red" }}>
                            {(item.s_amt_tot * (3 / 100)).toFixed(2)}
                          </td>
                          <td className="fw-semibold" style={{ color: "red" }}>
                            {item.m_amt < 0
                              ? item.s_amt_tot * (3 / 100)
                              : (
                                  item.m_amt * (2 / 100) +
                                  item.s_amt_tot * (3 / 100)
                                ).toFixed(2)}
                          </td>

                          <td className="fw-bold text-md">
                            {/* {item.m_amt < 0
                              ? (0 + item.c_com - item.c_com).toFixed(2)
                              : (
                                  item.m_amt *
                                    (item.user_accounts[0].user_agent
                                      .match_commission /
                                      100) +
                                  (item.c_com -
                                    item.m_amt *
                                      (item.user_accounts[0].user_agent
                                        .match_commission /
                                        100)) -
                                  item.c_com
                                ).toFixed(2)} */}
                            0.00
                          </td>

                          <td>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                handleResetCommission(item.id);
                              }}
                            >
                              Reset
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No User Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-5">
                  <div className="dataTables_info" id="example1_info">
                    Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                    {allData.length} entries
                  </div>
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example1_paginate"
                  >
                    <ul className="pagination">
                      <li
                        className={`paginate_button page-item previous ${
                          currentPage <= 1 ? "disabled" : ""
                        }`}
                        id="example1_previous"
                      >
                        <a
                          tabIndex="0"
                          className="page-link"
                          onClick={handlePrevious}
                        >
                          Previous
                        </a>
                      </li>
                      {[...Array(Math.ceil(data.length / itemsPerPage))].map(
                        (_, index) => {
                          const pageNumber = index + 1;
                          if (
                            pageNumber >= currentPage - 2 &&
                            pageNumber <= currentPage + 2
                          ) {
                            return (
                              <li key={index} className="page-item">
                                <a
                                  onClick={() => paginate(pageNumber)}
                                  className={`page-link ${
                                    currentPage === pageNumber
                                      ? "active-page-link"
                                      : ""
                                  }`}
                                >
                                  {pageNumber}
                                </a>
                              </li>
                            );
                          }
                          return null;
                        }
                      )}

                      <li
                        className={`paginate_button page-item next ${
                          currentPage >= Math.ceil(data.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        id="example1_next"
                      >
                        <a
                          tabIndex="0"
                          className="page-link"
                          onClick={handleNext}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
