/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./CreateNewSuperMaster.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useGetAdminAccount from "../../hooks/useGetAdminAccount";
import useUpdateAccount from "../../hooks/useUpdateAccount";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function UpdateSuperMaster() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");
  const { mutate: accountStatus } = useGetAccountStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;
  const { data: adminData } = useGetAdminAccount();
  const { mutate: updateAccount } = useUpdateAccount();
  const [parentData, setParentData] = useState([]);

  const [superAdminName, setSuperAdminName] = useState("");
  const [superAdminNumber, setSuperAdminNumber] = useState("");
  const [superAdminPassword, setSuperAdminPassword] = useState("");
  const [superAdminReference, setSuperAdminReference] = useState("");
  const [superAdminLimit, setSuperAdminLimit] = useState(0);
  const [superAdminShare, setSuperAdminShare] = useState(0);
  const [superAdminCasinoShare, setSuperAdminCasinoShare] = useState(0);
  const [superAdminMobileShare, setSuperAdminMobileShare] = useState(0);
  const [superAdminCommission, setSuperAdminCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [superAdminMatchCommission, setSuperAdminMatchCommission] = useState(0);
  const [superAdminSessionCommission, setSuperAdminSessionCommission] =
    useState(0);
  const [superAdminCassinoCommission, setSuperAdminCassinoCommission] =
    useState(0);
  const [clientCassinoStatus, setClientCassinoStatus] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (adminData?.data.data[0].user_id === uuid) {
      setParentData(adminData?.data.data[0]);
    } else {
      setParentData(adminData?.data.data[1]);
    }
  }, [adminData, uuid]);

  useEffect(() => {
    setSuperAdminName(userData.name);
    setSuperAdminNumber(userData.mobile);
    setSuperAdminPassword(userData.password);
    setSuperAdminReference(userData.reference);
    setSuperAdminLimit(userData.limit);
    setSuperAdminShare(userData.share);
    setSuperAdminCasinoShare(userData.cassino_share);
    setSuperAdminMobileShare(userData.mobile_share);
    setSuperAdminCommission(userData.commission);
    setSuperAdminMatchCommission(userData.match_commission);
    setSuperAdminSessionCommission(userData.session_commission);
    setSuperAdminCassinoCommission(userData.cassino_commission);
    setClientCassinoStatus(userData.cassino_status === 1 ? true : false);
  }, [userData]);

  const generateNewPassword = () => {
    setSuperAdminPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const userId = userData.id;

    const payload = {
      acc_type: "super_admin",
      id: userId,
      code: userData.code,
      name: superAdminName,
      reference: superAdminReference,
      password: String(superAdminPassword),
      parent: userData.parent,
      mobile: superAdminNumber,
      share: superAdminShare,
      cassino_share: superAdminCasinoShare,
      mobile_share: superAdminMobileShare,
      commission: superAdminCommission,
      match_commission: superAdminMatchCommission,
      session_commission: superAdminSessionCommission,
      cassino_commission: superAdminCassinoCommission,
      // cassino_status: clientCassinoStatus,
    };

    updateAccount(
      { userId, payload },
      {
        onSuccess: () => {
          setIsLoading(false);
          toast.success("Super Admin Updated Successfully");
          navigate("/supermaster");
        },
        onError: (error) => {
          setIsLoading(false);
          console.log(error);
          toast.error("Super Admin Update Failed");
        },
      }
    );
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Super Admin</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard" href="#">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/supermaster">Super Admin</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Edit Super Admin</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        id="create-button-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="superAdminName"
                            name="superAdminName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            value={superAdminName}
                            onChange={(e) => {
                              setSuperAdminName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="superAdminReference"
                            name="superAdminReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            value={superAdminReference}
                            onChange={(e) => {
                              setSuperAdminReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAdminPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="superAdminPassword"
                              name="superAdminPassword"
                              className="form-control"
                              required
                              value={superAdminPassword}
                              onChange={(e) => {
                                setSuperAdminPassword(e.target.value);
                              }}
                            />
                            <span
                              className="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAdminNumber"
                          >
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="superAdminNumber"
                            name="superAdminNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={10}
                            maxLength={10}
                            required
                            value={superAdminNumber}
                            onChange={(e) => {
                              setSuperAdminNumber(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample2">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        id="create-button-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Admin Limit
                            </label>
                            <input
                              type="number"
                              id="superAdminLimit"
                              name="superAdminLimit"
                              className="form-control"
                              disabled
                              value={superAdminLimit}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Admin Share
                            </label>
                            <input
                              type="number"
                              id="superAdminShare"
                              name="superAdminShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.share}
                              step="0.01"
                              required
                              value={superAdminShare}
                              disabled={accountType !== "admin"}
                              onChange={(e) => {
                                setSuperAdminShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Admin Casino Share
                            </label>
                            <input
                              type="number"
                              id="superAdminCasinoShare"
                              name="superAdminCasinoShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_share}
                              step="0.01"
                              required
                              value={superAdminCasinoShare}
                              disabled={accountType !== "admin"}
                              onChange={(e) => {
                                setSuperAdminCasinoShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select
                              className="form-select"
                              id="clientCassinoStatus"
                              name="clientCassinoStatus"
                              required
                              value={clientCassinoStatus}
                              disabled={true}
                              onChange={(e) => {
                                if (e.target.value === "true") {
                                  setClientCassinoStatus(true);
                                } else {
                                  setClientCassinoStatus(false);
                                }
                              }}
                            >
                              <option value={false}>OFF</option>
                              <option
                                value={true}
                                disabled={parentData?.cassino_status === 0}
                              >
                                ON
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="superAdminCommission"
                              name="superAdminCommission"
                              required
                              value={superAdminCommission}
                              onChange={(e) => {
                                setSuperAdminCommission(e.target.value);
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Admin Match Commission
                            </label>
                            <input
                              type="number"
                              id="superAdminMatchCommission"
                              name="superAdminMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.match_commission}
                              required
                              value={superAdminMatchCommission}
                              onChange={(e) => {
                                setSuperAdminMatchCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Admin Session Commission
                            </label>
                            <input
                              type="number"
                              id="superAdminSessionCommission"
                              name="superAdminSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.session_commission}
                              required
                              value={superAdminSessionCommission}
                              onChange={(e) => {
                                setSuperAdminSessionCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Admin Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="superAdminCassinoCommission"
                              name="superAdminCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_commission}
                              required
                              value={superAdminCassinoCommission}
                              onChange={(e) => {
                                setSuperAdminCassinoCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/supermaster");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isLoading}
                >
                  Update Super Admin
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
